import React, { useEffect } from "react";
import { generatePreProof } from "../../utils/interact";
import { useParams } from "react-router-dom";
import { StylesContext } from "@material-ui/styles";
import styles from "./MerkleProof.module.sass";

const MerkleProof = () => {
  const address = useParams().address;

  useEffect(() => {}, []);
  return (
    <>
      <div className={styles.box}>
        <h1>Premint Merkle Proof</h1>
        <h4>
          copy highlighted and paste into _merkleProof (bytes32[]) on etherscan
        </h4>
        <div className={styles.hilite}>{/*generatePreProof(address)*/}</div>
      </div>
    </>
  );
};

export default MerkleProof;
