export const traits = {
  Background: {
    _Bkg_1: "Deep Carmine Pink",
    _Bkg_2: "Mantis",
    _Bkg_4: "Viking Blue",
    _Bkg_5: "Pastel Orange",
    _Bkg_6: "Razzmatazz",
  },
  Patterns: {
    _Pattern_2: "Waves",
    _Pattern_3: "Vortex",
    _Pattern_6: "Zig Zag",
    _Pattern_7: "Shuriken",
    _Pattern_10: "Electric",
  },
  Shoes: {
    _Shoes_2: "Checkered",
    _Shoes_5: "Futuristic",
    _Shoes_6: "Big Foot",
    _Shoes_8: "Slides",
    _Shoes_9: "Socks",
  },
  Body: {
    _Body_1: "Fish",
    _Body_2: "Infected Mushroom",
    _Body_4: "Mohawk Shrimp",
    _Body_6: "Magical Stump",
    _Body_9: "Golden Snail Alien",
  },
  Rice: {
    _Rice_2: "Lucid",
    _Rice_3: "Tie Dye",
    _Rice_7: "Illusion",
    _Rice_8: "Pixeled",
    _Rice_10: "Brain",
  },
  Belt: {
    _Belt_1: "Party Studs",
    _Belt_6: "Eyes",
    _Belt_8: "Blue Triple Threat",
    _Belt_10: "Tapestry",
    _Belt_12: "GN Third Eye",
  },
  Eyes: {
    _Eye_3: "Three",
    _Eye_5: "Cyclops",
    _Eye_7: "Hippy Shades",
    _Eye_13: "Snowboard Goggles",
    _Eye_15: "Four Eyes",
  },
  Mouth: {
    _Mouth_2: "Mushroom",
    _Mouth_4: "Drooling",
    _Mouth_7: "Vape",
    _Mouth_9: "Golden Fangs",
    _Mouth_11: "Bicycle Day",
  },
};
