import { useEffect } from "react";
import { Hero } from "./Hero";
import { Steps } from "./Steps";
import { Drops } from "./Drops";
import styles from "./LandingPage.module.sass";
import Header from "../../components/Header";
import HeaderLanding from "../../components/HeaderLanding";
import Footer from "../../components/Footer";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderLanding />
      <div className={styles.container}>
        <Hero />
        <Steps />
        <Drops />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
