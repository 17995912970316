export const edenhomes = {
  contract: {
    network: "Ethereum",
    token: "ETH",
  },
  images: [
    {
      title: "33",
      image: "/images/content/edenhomes/home1.jpg",
      image2x: "/images/content/edenhomes/home1.jpg",
    },
    {
      title: "38",
      image: "/images/content/edenhomes/home2.jpg",
      image2x: "/images/content/edenhomes/home2.jpg",
    },
    {
      title: "44",
      image: "/images/content/edenhomes/home3.jpg",
      image2x: "/images/content/edenhomes/home3.jpg",
    },
    {
      title: "78",
      image: "/images/content/edenhomes/community.jpg",
      image2x: "/images/content/edenhomes/community.jpg",
    },
  ],
  activations: {
    title: "Roadmap",
    head: "",
    items: [
      {
        title: "10% sold",
        description: [
          "3-D printed constructed homes incorporating the latest building science technologies",
        ],
        date: "2 days ago",
        image: "/images/content/10p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "25% sold",
        description: [
          "Launch energy independent, solar micro-grid that produces more than consumed",
        ],
        date: "2 days ago",
        image: "/images/content/25p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "50% sold",
        description: [
          "On site food production from vertical indoor farms, community gardens, food forests",
        ],
        date: "2 days ago",
        image: "/images/content/50p.png",
        icon: "/images/content/play.svg",
        color: "#141416",
      },
      {
        title: "100% sold",
        description: [
          "Half of land is dedicated to natural landscapes for wildlife and pollinator gardens",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "Imagination Drive by Eden Homes",
    sale: "SALE - TBA",
    storyline:
      "Imagination Drive is the first community built by Eden Homes, accompanied by a soon-to-drop NFT collection. In the collection, each NFT represents the option to close on the home after development, with a total of thirty-four (34) pre-development contracts. Eden Homes develops self-sufficient communities using 3-D printed home technology – integrated with energy and food sustainability. On-site smart solar micro-grid ensure energy stability in all events, including all-electric appliances and EV charging that provide the framework for a fossil-free lifestyle. The heartbeat of every Eden community is a thriving natural environment. A diverse ecological community provides the backbone of an ecosystem that benefits all. As climate change continues to impact everyday life, Eden is working to address the double-edged sword of global warming and biodiversity loss at the community level.",
  },
  heroImage: {
    image: "/images/content/edenhomes/birdeye.jpg",
    image2x: "/images/content/edenhomes/birdeye.jpg",
    onClick: "https://www.youtube.com/watch?v=nKrDmFVEff8",
  },
  users: [
    {
      name: "Eden Homes",
      position: "Platform",
      avatar: "/images/content/edenhomes/hero.jpg",
    },
  ],
  discord: {
    title: "Join the Discord. Get Nifty for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/3d3nhomes",
    },
    {
      title: "discord",
      url: "https://discord.gg/YyYnKGTG",
    },
  ],
  faq: {
    content: {
      faqhead: "learn more about eden homes",
      faqdescription: "FAQ",
      cco: "",
      faqdomain: "",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "How many NFTs will be minted?",
            description:
              "The Imagination Drive project creates a community with forty-four homes. Thus, forty-four NFTs can be minted as pre-development option contracts.",
          },
          {
            title: "When does minting begin?",
            description: "To Be Announced",
          },
          {
            title: "How much do they cost to mint?",
            description: "Each home in Imagination Drive is 125 ETH to mint.",
          },
          {
            title:
              "Is there a limit on how many NFTs can be minted per transaction?",
            description:
              "Up to 1 NFT can be minted per transaction during the public mint phase.",
          },
          {
            title: "When is the reveal?",
            description:
              "Up to 1 NFT can be minted per transaction during the public mint phase.",
          },
        ],
      },
    ],
  },
};
