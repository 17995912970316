export const aijia = {
  contract: {
    network: "Ethereum",
    token: "ETH",
    name: "rockgods",
    address: "0x5a907A20138706E0Fb11Fdff6081a5530aF1073f",
    whitelist: "rockgods"
  },
  images: [
    {
      title: "33",
      image: "/images/content/aijia/image1.png",
      image2x: "/images/content/aijia/image1.png",
    },
    {
      title: "34",
      image: "/images/content/aijia/image2.png",
      image2x: "/images/content/aijia/image2.png",
    },
    {
      title: "35",
      image: "/images/content/aijia/image3.png",
      image2x: "/images/content/aijia/image3.png",
    },
    {
      title: "33",
      image: "/images/content/aijia/image4.png",
      image2x: "/images/content/aijia/image4.png",
    },
  ],
  activations: {
    title: "Roadmap",
    head: '10% of all sales generated from the RockGodNFT mint will be going to our two select charities supporting Musicians and Independent venues, the <a href="https://www.nivassoc.org">National Independent Venue Association</a> and the <a href="https://www.musiciansfoundation.org">Musicians Foundation</a>. ',
    items: [
      {
        title: "0% Sold",
        description: [
          "• 24hr whitelist presale",
          "• Discord livestream with John Delaney to talk about process and inspiration behind RockGods",
        ],
        date: "2 days ago",
        image: "/images/content/0p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "25% Sold",
        description: [
          "• 200 RockGods airdropped to random RockGod holders",
          "• AMA chat with RockGod artist, John Delaney for current holders",
        ],
        date: "2 days ago",
        image: "/images/content/25p.png",
        icon: "/images/content/play.svg",
        color: "#141416",
      },
      {
        title: "50% Sold",
        description: [
          "• A full band (5 RockGods) are raffled off and airdropped to a RockGod holder paired with 1 of 1 physical artwork of the entire band",
          "• 5 RockGod holders will be contacted and sent exclusive physical RockGod art pieces by renowned DC Comic artist, John Delaney",
        ],
        date: "2 days ago",
        image: "/images/content/50p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "66.6% Sold",
        description: [
          "• 10 RockGod holders will be contacted and sent exclusive 1 of 1 NFT art pieces from legendary producer and artist, KoOoLkOjAk",
          "• Another full band (5 RockGods) are raffled off and airdropped to a RockGod holder paired with 1 of 1 physical artwork of the entire band",
        ],
        date: "2 days ago",
        image: "/images/content/666p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "100% Sold",
        description: [
          "• Full rarity checker goes live",
          "• 3 physical 1 of 1 pieces of art from partner and artist, KoOoLkOjAk will be randomly sent to RockGod holders",
          "• Each RockGod gets their very own CELO-based copy of their RockGod along with pre-sale access to every concert on the upcoming Aijia ticketing platform",
          "• 10 RockGod holders are randomly invited to connect via Zoom to our partners for a 10 minute call",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "RockGodNFT",
    sale: "🤘 Visit the RockGodNFT Discord for the latest sale dates & times",
    storyline:
      "RockGods are the eternal beings watching over the Rock and Roll World. Beings of immense power, these Gods have been the voice in your favorite artists head when they scribble down the lyrics to their most iconic song. They are there in the crowd with you when you feel the electricity of a guitar solo rip through your bones. They headbang, scream and shout when they hear the riffs that echo across time. Each RockGod is unique and their influence on music and it's disciples is no different.",
  },
  heroImage: {
    image: "/images/content/aijia/collage.png",
    image2x: "/images/content/aijia/collage.png",
  },
  users: [
    {
      name: "Aijia",
      position: "Platform",
      avatar: "/images/content/aijia/aijia.png",
    },
  ],
  discord: {
    title: "Join the Discord. Get Nifty for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/RockGodNFT",
    },
    {
      title: "discord",
      url: "https://discord.gg/YX3g4PFHkC",
    },
    {
      title: "website",
      url: "https://aijia.fan",
    },
  ],
  faq: {
    content: {
      faqhead: "learn more about Rock Gods",
      faqdescription: "FAQ",
      cco: "Public Domain CC0",
      faqdomain:
        "To the extent possible under law, AIjia and John Delaney have waived all copyright and related or neighboring rights to RockGods. This work is published from: United States",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "How many RockGods will be minted?",
            description:
              "While there are over 70,000 total possible combinations, only 9,999 RockGod's will ever exist in the collection.",
          },
          {
            title: "When does the minting begin?",
            description:
              "We will announce 72hrs in advance, stay tuned! Pre-sale will run for 24hrs before public.",
          },
          {
            title: "How much do they cost to mint?",
            description: "Each RockGod is 0.09 ETH to mint.",
          },
          {
            title: "How big is the Whitelist and how do I get in?",
            description:
              "The whitelist will be open to the first 300 people who enter our Discord along with a few select others who participate in contests prior to drop. Whitelisting will allow you to purchase up to 20 RockGods 24hrs before the public drop.",
          },
          {
            title:
              "Is there a limit on how many RockGod's can be minted per transaction?",
            description:
              "Up to 20 RockGod's can be minted per transaction during the public mint phase.",
          },
          {
            title: "When is the reveal?",
            description:
              "RockGod's will be revealed in the RockGod and Roll Hall of Fame after all 9,999 are minted.",
          },
        ],
      },
    ],
  },
};
