export const sushi = {
  contract: {
    network: "Ethereum",
    token: "ETH",
    name: "niftybasemerkle",
    address: "0xaA5EeE2ce148D0D041D7C585496ef785FDc55150",
    whitelist: "whitelist2",
  },
  images: [
    {
      title: "sushia",
      image: "/images/content/sushi/sushia.png",
      image2x: "/images/content/sushi/sushia.png",
    },
    {
      title: "sushib",
      image: "/images/content/sushi/sushib.png",
      image2x: "/images/content/sushi/sushib.png",
    },
    {
      title: "sushic",
      image: "/images/content/sushi/sushic.png",
      image2x: "/images/content/sushi/sushic.png",
    },
    {
      title: "sushid",
      image: "/images/content/sushi/sushid.png",
      image2x: "/images/content/sushi/sushid.png",
    },
  ],
  activations: {
    title: "Roadmap - Omakase Menu",
    head: "",
    items: [
      {
        title: "Trippy Main Launch",
        description: ["• 12hr OG pre-presale", "• 24hr whitelist presale"],
        date: "2 days ago",
        image: "/images/content/0p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "10% Sold",
        description: [
          "• Snapshot of holders will receive an airdrop of a Trippy Sushi Companion",
        ],
        date: "2 days ago",
        image: "/images/content/10p.png",
        icon: "/images/content/play.svg",
        color: "#141416",
      },
      {
        title: "25% Sold",
        description: [
          "• Snapshot of holders for raffle of bonus Trippy Sushi NFTs",
          "• Regular DJ sessions on Twitch with POAPs dropped to attendees",
        ],
        date: "2 days ago",
        image: "/images/content/25p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "42.0% Sold",
        description: [
          "• Art collab ft. airdrops",
          "• Merch store w/ limited designs for holders & regular stock to follow up",
        ],
        date: "2 days ago",
        image: "/images/content/42p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "69% Sold",
        description: [
          "• 5x ultra rare 1/1 Trippy Sushi randomly airdropped to holders who minted",
          "• Charity donation (psychedelic-related)",
        ],
        date: "2 days ago",
        image: "/images/content/69p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "100% Sold",
        description: [
          "• Full rarity checker goes live",
          "• Community vote for charity to receive 6.9% of future royalties earned",
          "• Free mint for Sashimi Sidekick NFT (collection #2)",
          "• Trippy Tours (event featuring live music, sushi & who knows what else)",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "Trippy Sushi",
    sale: "🍣 Minting will be announced at any moment! Join the Trippy Sushi Discord & follow their Twitter for updates 🍣",
    storyline:
      "Trippy Sushi are mystic rice patty creatures transported from the galaxy Narezushi to Earth. In their journey, the Trippy Sushi's discovered wild mushrooms in a deep dark forest and evolved into the psychedelic characters you see today. Each Sushi carries with it special psychedelic traits, only to be learned by the Sushi owners themselves.",
  },
  heroImage: {
    image: "/images/content/sushi/trippysushi-group-3.png",
    image2x: "/images/content/sushi/trippysushi-group-3.png",
  },
  users: [
    {
      name: "Sushi Master",
      position: "Owner",
      avatar: "/images/content/sushi/default-sushi.png",
    },
    {
      name: "Sushi Itamae",
      position: "Developer",
      avatar: "/images/content/sushi/default-sushi.png",
    },
  ],
  discord: {
    title: "Join the Discord. Get Trippy for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/trippysushi",
    },
    {
      title: "discord",
      url: "https://discord.com/invite/trippysushi",
    },
    {
      title: "etherscan",
      url: "https://etherscan.io/address/0xaA5EeE2ce148D0D041D7C585496ef785FDc55150",
    },
  ],
  faq: {
    content: {
      faqhead: "learn more about sushi",
      faqdescription: "FAQ",
      cco: "Public Domain CC0",
      faqdomain:
        "To the extent possible under law, Sushi Creators have waived all copyright and related or neighboring rights to Trippy Sushi's. This work is published from:United States.",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "🍣 Wat Sushi?!",
            description:
              "Trippy Sushi! a 6969 strong collection of the coolest NFTs to hit your conveyor belts since… ever?! come on a trip with us.",
          },
          {
            title: "⏰ Wen Mint?!",
            description:
              "Dec 5th: OG presale<br/><br/>Dec 6th: WL presale<br/><br/>Dec 7th: PUBLIC mint",
          },
          {
            title: "💰 Wat Mint?!",
            description:
              "Cost is .05eth per mint. we will let the community vote on maximum quantities for minting!",
          },
          {
            title: "🗺 Wen Roadmap?!",
            description:
              "We have incredible plans for the project, check the discord",
          },
          {
            title: "📆 Wen Reveal?!",
            description: "Tell you soon soon!",
          },
          {
            title: "🔎 Wat R My Rights?!",
            description:
              "You have full commercial rights of your Trippy Sushi NFTs. you have authority to give others permission to use the artwork, and you can create/commission derivatives of your NFTs.",
          },
          {
            title: "🔥 Wasabi?!",
            description: "Ouch! that burns, bro.",
          },
          {
            title: "🙅‍♂️ Arms?!",
            description: "Nubs.",
          },
          {
            title: "💯 GMI?!",
            description: "WAGMI",
          },
        ],
      },
    ],
  },
};
