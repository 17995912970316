import React from "react";
import cn from "classnames";
import styles from "./ScoreModule.module.sass";
import Icon from "../../../components/Icon";

const items = [
  {
    title: "0.007",
    value: "ETH",
  },
  {
    title: "Your balance",
    value: "8.498 ETH",
  },
  {
    title: "Service fee",
    value: "0 ETH",
  },
  {
    title: "You will pay",
    value: "0.007 ETH",
  },
];

const ScoreModule = (props) => {
  return (
    <div className={cn(props.className, styles.checkout)}>
      <div className={cn("h4", styles.title)}>{props.title}</div>
      {/*}
      <div className={styles.attention}>
        <div className={styles.preview}>
          <Icon name="notification" size="32" />
        </div>
        <div className={styles.details}>
          <div className={styles.subtitle}>{props.subtitle}</div>
          <div className={styles.text}>{props.text}</div>
        </div>
      </div>
      */}
    </div>
  );
};

export default ScoreModule;
