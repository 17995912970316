import React, { useState } from "react";
import cn from "classnames";
import styles from "./NotFound.module.sass";
import Image from "../../components/Image";
import Form from "../../components/Form";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Icon from "../../components/Icon";

const NotFound = () => {
  const handleSubmit = (e) => {
    alert();
  };

  return (
    <>
      <Header />
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.preview}></div>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>404 - Page Not Found</h2>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
