import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import React, { useEffect } from "react";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import LandingPage from "./screens/LandingPage";
import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item";
import PageList from "./screens/PageList";
import NotFound from "./screens/NotFound";

import { sushi } from "./copy/sushi";
import { heartwater } from "./copy/heartwater";
import { aijia } from "./copy/aijia";
import { edenhomes } from "./copy/edenhomes";
import { daikon } from "./copy/daikon";
import { chopsticks } from "./copy/chopsticks";
import { flowers_md } from "./copy/flowers_md";
import { flowers_one } from "./copy/flowers_one";

import Builder from "./screens/Builder";
import ReactGA from "react-ga4";
import MerkleProof from "./screens/MerkleProof";

import { Interact } from "./utils/interact";

require("dotenv").config();
ReactGA.initialize(process.env.REACT_APP_GA);

function usePageViews() {
  let location = useLocation();
  console.log(location);
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    console.log(window.location.pathname);
  }, [location]);
}

function Routes() {
  usePageViews();
  // handle subdomain routing here
  if (window.location.host.split(".")[0] == "1800flowers") {
    return (
      <Switch>
        <Route
          exact
          path="/The-Love-of-a-Mom"
          render={() => <Home interact={new Interact(flowers_one)}/>}
        />
        <Route
          exact
          path="/Moms-Run-the-World"
          render={() => <Home interact={new Interact(flowers_md)}/>}
        />
        <Route
          exact
          path="/"
          render={() => <Home interact={new Interact(flowers_md)}/>}
        />
        <Route component={NotFound} />
      </Switch>
    );
  } else {
    // handle path routing here
    return (
      <Switch>
        <Route
          exact
          path="/trippy-sushi"
          render={() => <Home interact={new Interact(sushi)} />}
        />
        <Route
          exact
          path="/trippy-sushi/airdrop/daikon"
          render={() => <Home interact={new Interact(daikon)} />}
        />
        <Route
          exact
          path="/trippy-sushi/airdrop/chopsticks"
          render={() => <Home interact={new Interact(chopsticks)} />}
        />
        <Route
          exact
          path="/trippy-sushi/merkletest/:address"
          render={() => <Home interact={new Interact(sushi)} />}
        />
        <Route
          exact
          path="/melting-hearts"
          render={() => <Home interact={new Interact(heartwater)} />}
        />
        <Route
          exact
          path="/rock-gods"
          render={() => <Home interact={new Interact(aijia)} />}
        />
        <Route
          exact
          path="/eden-homes"
          render={() => <Home interact={new Interact(edenhomes)} />}
        />
        <Route
          exact
          path="/trippy-sushi/staging"
          render={() => <Home interact={new Interact(sushi)} />}
        />
        <Route
          exact
          path="/trippy-sushi/build"
          render={() => <Builder interact={new Interact(sushi)} />}
        />
        <Route
          exact
          path="/trippy-sushi/merkleproof/:address"
          render={() => <MerkleProof interact={new Interact(sushi)} />}
        />
        <Route exact path="/" render={() => <LandingPage />} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

/*
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Home />
          )}
        />
        <Route
          exact
          path="/upload-variants"
          render={() => (
            <Page>
              <UploadVariants />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page>
              <UploadDetails />
            </Page>
          )}
        />
        <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page>
              <ConnectWallet />
            </Page>
          )}
        />
        <Route
          exact
          path="/faq"
          render={() => (
            <Page>
              <Faq />
            </Page>
          )}
        />
        <Route
          exact
          path="/activity"
          render={() => (
            <Page>
              <Activity />
            </Page>
          )}
        />
        <Route
          exact
          path="/search01"
          render={() => (
            <Page>
              <Search01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/search02"
          render={() => (
            <Page>
              <Search02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile"
          render={() => (
            <Page>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={() => (
            <Page>
              <ProfileEdit />
            </Page>
          )}
        />
        <Route
          exact
          path="/item"
          render={() => (
            <Page>
              <Item />
            </Page>
          )}
        />
        <Route
          exact
          path="/pagelist"
          render={() => (
            <Page>
              <PageList />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );

}
*/

export default App;
