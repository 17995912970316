import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import { useLocation, useParams } from "react-router-dom";
import styles from "./Hero.module.sass";
import Icon from "../../../components/Icon";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import Users from "../../Item/Users";
import FAQHero from "../../Faq/Hero";
import Modal from "../../../components/Modal";
import SuccessfullyPurchased from "../SuccessfullyPurchased";
import Error from "./Error";
import MintBox from "./MintBox";
import DiscordButton from "../../../components/Discord";
import ReactHtmlParser from "react-html-parser";
import Countdown from "./Countdown";
import ClipLoader from "react-spinners/ClipLoader";
import Loader from "../../../components/LoaderCircle";

import LoadingOverlay from 'react-loading-overlay';

const Hero = ({ interact }) => {
  const [visibleModalPurchase, setVisibleModalPurchase] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [mintCount, setMintCount] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [txHash, setTxHash] = useState("");
  const [processing, setProcessing] = useState(true);
  const [visibleModalError, setVisibleModalError] = useState(false);
  const [errorSubtitle, setErrorSubtitle] = useState("");
  const [errorText, setErrorText] = useState("");

  const [saleType, setSaleType] = useState("main");

  const [canMintMax, setCanMintMax] = useState(0);
  const [maxTokens, setMaxTokens] = useState(0);

  const [spinnerActive, setSpinnerActive] = useState(false);

  // update this for countdown
  const endTimestamp = 1639227079000;

  // get mock address for testing
  const path = useLocation().pathname;
  const mockAddress = useParams().address;

  const lastMinuteOG = [
    "0xA2C2D9340c5F0D0Bd3103838479a04E7ab0A0110",
    "0xe055721B972D58F0bCf6370C357879fb3a37d2f3",
    "0xad6637f679C7b733546eb591b9a636aC833787a0",
    "0x1e339F6493A17BDe08D7B5c3F8B2567C44800B08",
    "0x1e339F6493A17BDe08D7B5c3F8B2567C44800B08",
    "0xeab2f93aD71709832E17C861D397BCB1F7f96159",
    "0xB9aC84E31dAf0cE4eF2EC97077D4267521B7ae3f",
    "0xddD4A584D337B66CDaFc471762fBA0B940ccF985",
    "0xB8De0d58fE1724552Ff860592448Bc123c4F0c04"
  ]

  useEffect(() => {
    (async function () {
      //const { address, status } = await interact.getCurrentWalletConnected();
      //setWallet(address);
      //addWalletListener();
      await updateTokenPrice();
      await updateTotalMinted();
      await updateCanMintMax();
      await updateMaxTokens();
      setSaleType(await interact.getCurrentSale());
      // change network
      if (window.ethereum) {
        window.ethereum.on("chainChanged", (chainId) => {
          window.location.reload();
        });
      }
    })();
  }, []);

  let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  // called externally from header
  const connectWalletPressed = async () => {
    const walletResponse = await interact.connectWallet();
    // let biconomy initiate
    setSpinnerActive(true)
    await sleep(4000)
    setSpinnerActive(false)
    // finish initiation
    if (walletResponse.address) {
      console.log("Connected Wallet: " + walletResponse.address);
      setWallet(walletResponse.address);
    } else {
      console.log("Could not connect to wallet");
      setErrorSubtitle(
        "Looks like your wallet may not be on Polygon, please add the network and switch over!"
      );
      setVisibleModalError(true);
    }
  };

  // called externally from group buttons
  const updateMintCount = (count) => {
    setMintCount(count);
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
          setErrorSubtitle(
            "🦊 Connect to Metamask using the top right button."
          );
          setVisibleModalError(true);
        }
      });
    } else {
      //setErrorSubtitle("You must install Metamask, a virtual Ethereum wallet, in your browser")
      //setVisibleModalError(true)
    }
  }

  const updateMaxTokens = async () => {
    setMaxTokens(await interact.getMaxTokens());
  };

  const updateCanMintMax = async () => {
    setCanMintMax(await interact.getMaxMints());
  };

  const updateTokenPrice = async () => {
    const weiPrice = await interact.getTokenPrice();
    const etherPrice = interact.toEther(weiPrice);
    setTokenPrice(etherPrice);
  };

  const updateTotalMinted = async () => {
    setTotalMinted(await interact.getTotalMinted());
  };

  const onMint = async () => {
    var mintAddress;

    console.log("->IN MINT");
    console.log("wallet address: " + walletAddress);
    mintAddress = walletAddress;

    // check mock address for /merkltest/:address route
    console.log("mockAddress: " + mockAddress);
    if (mockAddress) {
      mintAddress = mockAddress;
    }

    console.log("mintAddress: " + mintAddress);

    if (saleType == "og" && (!interact.isOg(mintAddress) && !lastMinuteOG.includes(interact.toChecksumAddress(mintAddress)))) {
      setErrorSubtitle("😪 Sorry you're not on the OG sale list");
      setVisibleModalError(true);
      return;
    }
    if (saleType == "pre" && !interact.isPre(mintAddress)) {
      setErrorSubtitle("😪 Sorry you're not on the PRE sale list");
      setVisibleModalError(true);
      return;
    }

    const res = await interact.mintNifties(mintCount, mintAddress);

    if (res.success == true) {
      console.log(res.txhash);

      // TxHash seemed like it would be set to 0 on some instances
      setTxHash(res.txhash);
      setVisibleModalPurchase(true);

      /*
      await pendingTransaction(res.txhash)
        .then((value) => {
          console.log(value);
          setProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setVisibleModalPurchase(false);
          setErrorSubtitle(error);
          setVisibleModalError(true);
        });
        */

      /* DEBUG
      setTimeout(function () {
        setProcessing(false)
      }, 2000);
      */
    } else {
      setErrorSubtitle(res.status);
      setVisibleModalError(true);
    }
  };

  return (
    <>
      {interact.copy.header ? (
        <Header handleConnect={connectWalletPressed} address={walletAddress} header={interact.copy.header} />
      ):(
        <Header handleConnect={connectWalletPressed} address={walletAddress} />
      )}
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.wrapper}>
            <div className={styles.row}>
              <div className={styles.preview}>
                <img src={interact.copy.heroImage.image}></img>
              </div>
              <div className={styles.details}>
                <div className={styles.head}>
                  <h2 className={styles.title}>{interact.copy.content.name}</h2>
                  <div className={styles.hairline}>
                    {ReactHtmlParser(interact.copy.content.sale)}
                  </div>
                  <div className={styles.stage}>
                    {interact.copy.content.storyline}
                  </div>
                </div>
                {/* TIMER */}
                {/*<Countdown endTimestamp={endTimestamp}/>*/}
                {/* Mintbox or EmailBox Render */}
                <LoadingOverlay
                  active={spinnerActive}
                  spinner
                >
                {true ? (
                  <div className={styles.wrap}>
                    <div className={styles.info}>
                      {interact.copy.discord.title}
                    </div>
                    <DiscordButton url={interact.copy.socials[1].url} />
                  </div>
                ) : (
                  <MintBox
                    totalMinted={totalMinted}
                    maxMinted={maxTokens}
                    canMint={canMintMax}
                    mintCount={mintCount}
                    totalPrice={tokenPrice}
                    onClick={onMint}
                    onWalletClick={connectWalletPressed}
                    updateMintCount={updateMintCount}
                    contractLink={interact.getEtherscanURL()}
                    walletAddress={walletAddress}
                    network={interact.copy.contract.network}
                    token={interact.copy.contract.token}
                  />
                )}
                </LoadingOverlay>
              </div>
              {/* Socials */}
              <div className={styles.socials}>
                {interact.copy.socials.map((x, index) => (
                  <a
                    className={styles.social}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <Icon name={x.title} size="24" />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Gallery Section */}
          <div className={cn("container", styles.container)}>
            <div className={styles.wrapper}>
              <div className={styles.galleryTitle}>Preview Gallery</div>
              <div className={styles.row}>
                <div className={styles.gridLayout}>
                  {interact.copy.images.map((x, index) => (
                    <Card key={index} className={styles.card} item={x} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.center}>
            <div className={styles.row}>
              <div className={styles.wrapper}>
                <h2 className={styles.activationTitle}>
                  {interact.copy.activations.title}
                </h2>
                <h2 className={styles.activationHead}>
                  {ReactHtmlParser(interact.copy.activations.head)}
                </h2>
                <div className={styles.list}>
                  {interact.copy.activations.items.map((x, index) => (
                    <div className={styles.activationItem} key={index}>
                      <div className={styles.activationPreview}>
                        <img src={x.image} alt="Notification" />
                      </div>
                      <div className={styles.activationDetails}>
                        <div className={styles.activationSubtitle}>
                          {x.title}
                        </div>
                        {x.description.map((x, index) => (
                          <div
                            className={styles.activationDescription}
                            key={index}
                          >
                            {ReactHtmlParser(x)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.userWrapper}>
                <h3 className={styles.hairline}>Creators</h3>
                <Users className={styles.users} items={interact.copy.users} />
              </div>
            </div>
          </div>
          <FAQHero faq={interact.copy.faq} />
        </div>
        <div className={styles.center}>
          <div>{interact.copy.faq.content.cco}</div>
          <div>{interact.copy.faq.content.faqdomain}</div>
        </div>
      </div>
      {/*
      <Modal
        visible={spinnerActive}
        onClose={() => setSpinnerActive(false)}
      >
        <ClipLoader loading={true}/>
      </Modal>
      */}
      <Modal
        visible={visibleModalPurchase}
        onClose={() => setVisibleModalPurchase(false)}
      >
        <SuccessfullyPurchased
          tx={txHash}
          processing={processing}
          interact={interact}
        />
        {/*{!processing && <Accept />}*/}
      </Modal>
      <Modal
        visible={visibleModalError}
        onClose={() => {
          setVisibleModalError(false);
          setErrorSubtitle("");
          setErrorText("");
        }}
      >
        <Error subtitle={errorSubtitle} text={errorText} />
      </Modal>
      <Footer />
    </>
  );
};

export default Hero;
