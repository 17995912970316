export const flowers_md = {
  contract: {
    network: "Polygon",
    token: "MATIC",
    name: "mom",
    address: "0x748138789B555ADb7D1610042fF2B0D743964DC1",
    whitelist: "flowers",
  },
  mint: {
    title: "Opensea Collection",
    link: "https://opensea.io/collection/moms-run-the-world",
    linkTitle: "1800Flowers-Mom"
  },
  header: {
    src: "/images/content/flowers1800/18F-color.png",
    alt: "1800flowers",
    width: "128px",
    height: "64px",
    title: null
  },
  images: [
    {
      title: "sushia",
      image: "/images/content/flowers1800/md-drop/md-nft-1.png",
      image2x: "/images/content/flowers1800/md-drop/md-nft-1.png",
    },
    {
      title: "sushib",
      image: "/images/content/flowers1800/md-drop/md-nft-2.png",
      image2x: "/images/content/flowers1800/md-drop/md-nft-2.png",
    },
    {
      title: "sushic",
      image: "/images/content/flowers1800/md-drop/md-nft-3.png",
      image2x: "/images/content/flowers1800/md-drop/md-nft-3.png",
    },
    {
      title: "sushid",
      image: "/images/content/flowers1800/md-drop/md-nft-4.png",
      image2x: "/images/content/flowers1800/md-drop/md-nft-4.png",
    },
  ],
  activations: {
    title: "Mother's Day Giveaway",
    head: "",
    items: [
      {
        title: "Mom's Run the World",
        description: [
          "• 1,800 Mother’s Day NFT pfp collectibles",
          "• Powered by: Polygon (MATIC)",
          "• FREE for giveaway winners",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "Moms Run the World",
    sale: "1-800-Flowers, Mother's Day Giveaway",
    storyline:
      "Moms Run the World is a diverse collection of Moms who run the world blossoming their children through love and attention. We celebrate them with 1,800 unique PFP NFTs that you can collect and showcase to celebrate your mom.",
  },
  heroImage: {
    image: "/images/content/flowers1800/md-drop/md-nft-collage.png",
    image2x: "/images/content/flowers1800/md-drop/md-nft-collage.png",
  },
  users: [
    {
      name: "Devi Namira",
      position: "NFT Illustrator",
      avatar: "/images/content/flowers1800/md-drop/md-nft-5.png",
    }
  ],
  discord: {
    title: "Join the Discord. Get ready for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/1800flowers",
    },
    {
      title: "discord",
      url: "https://discord.com/invite/1800flowers",
    },
    {
      title: "polygonscan",
      url: "https://polygonscan.com/address/0x748138789B555ADb7D1610042fF2B0D743964DC1",
    }
  ],
  faq: {
    content: {
      faqhead: "learn more about sushi",
      faqdescription: "FAQ",
      cco: "",
      faqdomain:
        "",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "What is an NFT?",
            description:
              "In short, NFTs (or non-fungible tokens) are authenticated, one-of-a-kind digital collectibles. Our NFT collections are pieces of digital art that celebrate mothers. If you want to learn more about NFTs, check out this great rundown from The Verge.",
          },
          {
            title: "Why is 1-800-FLOWERS.COM, Inc. releasing NFTs?",
            description: "We are always looking for more ways to innovate and celebrate life’s most important moments! For Mother’s Day this year, we’re celebrating the moms who run the world. We partnered with the artist, Devi Namira to create our own NFT collection with 1,800 pfp collectibles.",
          },
          {
            title: "How much does it cost??",
            description: "The Moms Run the World NFT collection is FREE for all giveaway winners.",
          },
          {
            title: "What blockchain network?",
            description: "Our NFT collection will live on the Polygon (MATIC) network.",
          },
        ],
      },
    ],
  },
};