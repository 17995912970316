import React, { useEffect } from "react";
import Hero from "./Hero";

const Home = ({ interact }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero interact={interact} />
    </>
  );
};

export default Home;
