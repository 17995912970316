import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./Builder.module.sass";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import mergeImages from "merge-images";
import Select from "react-select";
import { traits } from "./traits.js";
import { style } from "dom-helpers";
import TextInput from "../../components/TextInput";
import Error from "../Home/Hero/Error";
import Modal from "../../components/Modal";
import ScoreModule from "./ScoreModule";

const Builder = ({ interact }) => {
  const [combinedImage, setCombinedImage] = useState(null);

  const [background, setBackground] = useState("");
  const [patterns, setPatterns] = useState("");
  const [shoes, setShoes] = useState("");
  const [body, setBody] = useState("");
  const [rice, setRice] = useState("");
  const [belt, setBelt] = useState("");
  const [eyes, setEyes] = useState("");
  const [mouth, setMouth] = useState("");

  const [discord, setDiscord] = useState("");
  const [score, setScore] = useState("0");

  const [walletAddress, setWallet] = useState("");

  const [visibleModalError, setVisibleModalError] = useState(false);
  const [errorSubtitle, setErrorSubtitle] = useState("");
  const [errorText, setErrorText] = useState("");

  const [visibleModalScore, setVisibleModalScore] = useState(false);

  // used to produce a signature for verification
  const message = "good luck fam!";

  const layerOrder = [
    background,
    patterns,
    shoes,
    body,
    rice,
    belt,
    eyes,
    mouth,
  ];
  const baseImageURI = "/images/content/sushi/layers/";

  const generateOptions = (trait) => {
    const options = [];
    for (const [key, val] of Object.entries(traits[trait])) {
      options.push({
        value: "".concat(baseImageURI, trait, "/", key, ".png"),
        label: val,
      });
    }
    return options;
  };

  const select = [
    {
      title: "Background",
      selectedOption: background,
      method: setBackground,
      options: generateOptions("Background"),
    },
    {
      title: "Patterns",
      selectedOption: patterns,
      method: setPatterns,
      options: generateOptions("Patterns"),
    },
    {
      title: "Shoes",
      selectedOption: shoes,
      method: setShoes,
      options: generateOptions("Shoes"),
    },
    {
      title: "Body",
      selectedOption: body,
      method: setBody,
      options: generateOptions("Body"),
    },
    {
      title: "Rice",
      selectedOption: rice,
      method: setRice,
      options: generateOptions("Rice"),
    },
    {
      title: "Belt",
      selectedOption: belt,
      method: setBelt,
      options: generateOptions("Belt"),
    },
    {
      title: "Eyes",
      selectedOption: eyes,
      method: setEyes,
      options: generateOptions("Eyes"),
    },
    {
      title: "Mouth",
      selectedOption: mouth,
      method: setMouth,
      options: generateOptions("Mouth"),
    },
  ];

  useEffect(() => {
    (async function () {
      const { address, status } = await interact.getCurrentWalletConnected();
      setWallet(interact.toChecksumAddress(address));
      addWalletListener();
    })();
  }, []);

  useEffect(() => {
    updateImage();
  }, [background, patterns, shoes, body, rice, belt, eyes, mouth]);

  const connectWalletPressed = async () => {
    const walletResponse = await interact.connectWallet();
    if (walletResponse.address) {
      setWallet(interact.toChecksumAddress(walletResponse.address));
    } else {
      setErrorSubtitle(
        "You must install Metamask, a virtual Ethereum wallet, in your browser"
      );
      setVisibleModalError(true);
    }
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(interact.toChecksumAddress(accounts[0]));
        } else {
          setWallet("");
          setErrorSubtitle(
            "🦊 Connect to Metamask using the top right button."
          );
          setVisibleModalError(true);
        }
      });
    } else {
      //setErrorSubtitle("You must install Metamask, a virtual Ethereum wallet, in your browser")
      //setVisibleModalError(true)
    }
  }

  const updateImage = () => {
    const imArray = [];
    layerOrder.map((layer, id) => {
      if (layer != "") {
        imArray.push(layer["value"]);
      }
    });
    if (imArray.length == 0) {
      setCombinedImage("/images/content/sushi/watermark.png");
    } else {
      mergeImages(imArray).then((b64) => setCombinedImage(b64));
    }
    //console.log(layerOrder)
  };

  const onSubmitSushi = async () => {
    // check discord and address
    if (discord == "" || walletAddress == "") {
      setErrorSubtitle(
        "Either wallet is not connected or Discord field is empty"
      );
      setVisibleModalError(true);
      return;
    }

    // sign message
    const signature = await interact.signMessage(message, walletAddress);

    const data = {};
    const traitList = [];
    // check for layers
    for (const layer of layerOrder) {
      if (layer == "") {
        alert("Layers aren't filled in!");
        return;
      }
      traitList.push(layer["label"]);
    }

    data["layers"] = traitList;
    data["discord"] = discord;
    data["id"] = walletAddress;
    data["signature"] = signature;

    const jsonData = JSON.stringify(data);
    //console.log(jsonData)

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: jsonData,
    };

    fetch("/api", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setScore(data.score);
        setVisibleModalScore(true);
      })
      .catch((error) => {
        console.log(error);
        setErrorSubtitle("Something wrong happened 😪");
        setVisibleModalError(true);
      });
  };

  return (
    <>
      <Header handleConnect={connectWalletPressed} address={walletAddress} />
      <div className={cn("container", styles.container)}>
        <div className={styles.title}>Build-a-Sushi</div>
        <div className={styles.gridContainer}>
          <div className={styles.imagebox}>
            <img src={combinedImage} className={styles.image}></img>
            <div className={styles.scoretitle}>{"Score:  " + score}</div>
          </div>
          <div className={styles.formselect}>
            {select.map((item, id) => {
              return (
                <div className={styles.selectitem} key={id}>
                  <div className={styles.selecttitle}>{item.title}</div>
                  <Select
                    defaultValue={item.selectedOption}
                    onChange={item.method}
                    options={item.options}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.submit}>
          <div className={styles.address}>
            <TextInput
              className={styles.field}
              label="Ethereum Address"
              name="Address"
              type="text"
              placeholder="Connect Wallet"
              disabled={true}
              defaultValue={walletAddress}
              required
            />
          </div>
          <div className={styles.discord}>
            <TextInput
              className={styles.field}
              label="Discord Handle"
              name="Discord"
              type="text"
              value={discord}
              onChange={(e) => setDiscord(e.target.value)}
              placeholder="name#123"
              required
            />
          </div>
          <div className={styles.sbutton}>
            <button
              className={cn("button", styles.button)}
              onClick={onSubmitSushi}
            >
              Submit Sushi 🍣
            </button>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleModalError}
        onClose={() => {
          setVisibleModalError(false);
          setErrorSubtitle("");
          setErrorText("");
        }}
      >
        <Error subtitle={errorSubtitle} text={errorText} />
      </Modal>
      <Modal
        visible={visibleModalScore}
        onClose={() => {
          setVisibleModalScore(false);
        }}
      >
        <ScoreModule title={"Score: " + score + " 🎉"} />
      </Modal>
      <Footer />
    </>
  );
};

export default Builder;
