export const chopsticks = {
  contract: {
    network: "Polygon",
    token: "MATIC",
    name: "niftybasemerkle",
    address: "0x9652d5C3b415507ceff49B8eF60A52b2c9139456",
    whitelist: "chopsticks-wl",
  },
  images: [
    {
      title: "sushia",
      image: "/images/content/sushi/trippy-chopsticks.png",
      image2x: "/images/content/sushi/trippy-chopsticks.png",
    },
    {
      title: "sushib",
      image: "/images/content/sushi/trippy-chopsticks.png",
      image2x: "/images/content/sushi/trippy-chopsticks.png",
    },
    {
      title: "sushic",
      image: "/images/content/sushi/trippy-chopsticks.png",
      image2x: "/images/content/sushi/trippy-chopsticks.png",
    },
    {
      title: "sushid",
      image: "/images/content/sushi/trippy-chopsticks.png",
      image2x: "/images/content/sushi/trippy-chopsticks.png",
    },
  ],
  activations: {
    title: "Free Mint: Trippy Chopsticks",
    head: "",
    items: [
      {
        title: "Free Mint on Polygon",
        description: [
          "• Free Mint of Trippy Chopsticks, Trippy Sushi Cutlery",
          "• Network: Polygon, Must Have MATIC Token",
          "• Only for Trippy Sushi Holders",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "Trippy Chopsticks",
    sale: "Free mint now and forever",
    storyline:
      "Behold the TRIPPY CHOPSTICKS, an exclusive bread of hashi. Made only for the trippiest of sushi.",
  },
  heroImage: {
    image: "/images/content/sushi/trippy-chopsticks.png",
    image2x: "/images/content/sushi/trippy-chopsticks.png",
  },
  users: [
    {
      name: "Sushi Master",
      position: "Owner",
      avatar: "/images/content/sushi/default-sushi.png",
    },
    {
      name: "Sushi Itamae",
      position: "Developer",
      avatar: "/images/content/sushi/default-sushi.png",
    },
  ],
  discord: {
    title: "Join the Discord. Get Trippy for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/trippysushi",
    },
    {
      title: "discord",
      url: "https://discord.com/invite/trippysushi",
    },
    {
      title: "polygonscan",
      url: "https://polygonscan.com/address/0x0895B39D2b4126d2e7b4dC35E5457578292388a5",
    },
  ],
  faq: {
    content: {
      faqhead: "learn more about sushi",
      faqdescription: "FAQ",
      cco: "Public Domain CC0",
      faqdomain:
        "To the extent possible under law, Sushi Creators have waived all copyright and related or neighboring rights to Trippy Sushi's. This work is published from:United States.",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "🧄 Wat Trippy Chopsticks?!",
            description:
              "Behold the TRIPPY CHOPSTICKS, an exclusive bread of hashi. Made only for the trippiest of sushi.",
          },
          {
            title: "⏰ Wen Mint?!",
            description: "Now and Forever",
          },
          {
            title: "💰 How Much Mint?!",
            description: "Free to Mint",
          },
          {
            title: "🗺 Wat Network?!",
            description: "Polygon",
          },
        ],
      },
    ],
  },
};
