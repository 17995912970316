export const flowers_one = {
  contract: {
    network: "Polygon",
    token: "MATIC",
    name: "niftybasemerklemeta",
    address: "0x23D35F5026E5E57229D877EDBC391A3340e58366",
    whitelist: "whitelist-test",
  },
  mint: {
    title: "Opensea Collection",
    link: "https://opensea.io/collection/1800-test",
    linkTitle: "1800-test"
  },
  header: {
    src: "/images/content/flowers1800/18F-color.png",
    alt: "1800flowers",
    width: "128px",
    height: "64px",
    title: null
  },
  images: [
    {
      title: "sushia",
      image: "/images/content/flowers1800/one-drop/12.jpeg",
      image2x: "/images/content/flowers1800/one-drop/12.jpeg",
    },
    {
      title: "sushib",
      image: "/images/content/flowers1800/one-drop/3.jpeg",
      image2x: "/images/content/flowers1800/one-drop/3.jpeg",
    },
    {
      title: "sushic",
      image: "/images/content/flowers1800/one-drop/9.jpeg",
      image2x: "/images/content/flowers1800/one-drop/9.jpeg",
    },
    {
      title: "sushid",
      image: "/images/content/flowers1800/one-drop/6.jpeg",
      image2x: "/images/content/flowers1800/one-drop/6.jpeg",
    },
  ],
  activations: {
    title: "Mother's Day Giveaway",
    head: "",
    items: [
      {
        title: "Mom's Run the World",
        description: [
          "• 180 Mother’s Day NFT collectibles",
          "• Powered by: Polygon (MATIC)",
          "• FREE for giveaway winners",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "The Love of a Mom",
    sale: "1-800-Flowers, Mother's Day Giveaway",
    storyline:
      "As we prepare to celebrate Mother’s Day, we captured in our art the special and unique bond that is fostered between a child and mother. A mother’s nurturing hands shape our perception of the world and her first lessons of love shape our minds, hopes and dreams. Similarly, a child’s love for their mother figure is reflected through the way they grasp her hand and are guided through the ever-blooming garden of life. We celebrate this special love with a collection of 180 NFTs.",
  },
  heroImage: {
    image: "/images/content/flowers1800/one-drop/collage.jpeg",
    image2x: "/images/content/flowers1800/one-drop/collage.jpeg",
  },
  users: [
    {
      name: "Maax Rahell ",
      position: "NFT Artist",
      avatar: "/images/content/flowers1800/md-drop/md-nft-6.png",
    }
  ],
  discord: {
    title: "Join the Discord. Get ready for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/1800flowers",
    },
    {
      title: "discord",
      url: "",
    },
    {
      title: "polygonscan",
      url: "https://polygonscan.com/address/0x23D35F5026E5E57229D877EDBC391A3340e58366",
    }
  ],
  faq: {
    content: {
      faqhead: "learn more about sushi",
      faqdescription: "FAQ",
      cco: "",
      faqdomain:
        "",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "What is an NFT?",
            description:
              "In short, NFTs (or non-fungible tokens) are authenticated, one-of-a-kind digital collectibles. Our NFT collections are pieces of digital art that celebrate mothers. If you want to learn more about NFTs, check out this great rundown from The Verge.",
          },
          {
            title: "Why is 1-800-FLOWERS.COM, Inc. releasing NFTs?",
            description: "We are always looking for more ways to innovate and celebrate life’s most important moments! For Mother’s Day this year, we’re celebrating the moms who run the world. We partnered with two artists, Devi Namira and Maax Rahell to create our own NFT collection with 1,800 pfp collectibles.",
          },
          {
            title: "How much does it cost?",
            description: "The Moms Run the World NFT collection is FREE for all giveaway winners.",
          },
          {
            title: "What blockchain network?",
            description: "Our NFT collection will live on the Polygon (MATIC) network.",
          },
        ],
      },
    ],
  },
};