import React from "react";
import cn from "classnames";
import styles from "./SuccessfullyPurchased.module.sass";
import Icon from "../../../components/Icon";
import LoaderCircle from "../../../components/LoaderCircle";
import { getEtherscanURL } from "../../../utils/interact";

const rinkebyEtherscanUrl = "https://rinkeby.etherscan.io/tx/";
const mainEtherscanUrl = "https://etherscan.io/tx/";

const socials = [
  {
    title: "facebook",
    url: "https://www.facebook.com/ui8.net/",
  },
  {
    title: "twitter",
    url: "https://twitter.com/ui8",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/ui8net/",
  },
  {
    title: "pinterest",
    url: "https://www.pinterest.com/ui8m/",
  },
];

const SuccessfullyPurchased = (props) => {
  return (
    <div className={cn(props.className, styles.wrap)}>
      <div className={cn("h2", styles.title)}>
        Yay{" "}
        <span role="img" aria-label="firework">
          🥳
        </span>
      </div>
      <div className={styles.info}>
      Congrats minting your NFT!
        {/*Transaction in progress <span>Happy Sushi</span> in progress */}
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          {/*<div className={styles.col}>Status</div>*/}
          {
            props.interact.copy.mint ? (
              <div className={styles.col}>{props.interact.copy.mint.title}</div>
            ):(
              <div className={styles.col}>Transaction Hash</div>
            )
          }
          <div className={styles.col}>
            {
              props.interact.copy.mint ? (
                <a
                  href={props.interact.copy.mint.link}
                  target="_blank"
                >
                  {props.interact.copy.mint.linkTitle} 
                </a>
              ) :  props.tx ? (
                <a
                  href={props.interact.getEtherscanURL() + "/tx/" + props.tx}
                  target="_blank"
                >
                  {String(props.tx).substring(0, 6) +
                    "..." +
                    String(props.tx).substring(62)}
                </a>
              ):(
                <></>
              )
            }
            { /*
              props.tx ? (
                <a
                  href={props.interact.getEtherscanURL() + "/tx/" + props.tx}
                  target="_blank"
                >
                  {String(props.tx).substring(0, 6) +
                    "..." +
                    String(props.tx).substring(62)}
                </a>
              ):(
                <></>
              )
              */
            }
          </div>
        </div>
        <div className={styles.row}>
          {/*}
          <div className={styles.col}>
            {props.processing ? "Processing" : "Mined"}
          </div>
          */}
        </div>
      </div>
      {/*}
      <div className={styles.stage}>Time to show-off</div>
      <div className={styles.socials}>
        {socials.map((x, index) => (
          <a
            className={styles.social}
            href={x.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            <Icon name={x.title} size="24" />
          </a>
        ))}
      </div>
        */}
    </div>
  );
};

export default SuccessfullyPurchased;
