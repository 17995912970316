export const heartwater = {
  contract: {
    network: "Ethereum",
    token: "ETH",
    name: "niftybasemerkle",
    address: "0xaA5EeE2ce148D0D041D7C585496ef785FDc55150", //change me
    whitelist: "whitelist2", //change me
  },
  images: [
    {
      title: "38",
      image: "/images/content/heartwater/9.png",
      image2x: "/images/content/heartwater/9.png",
    },
    {
      title: "44",
      image: "/images/content/heartwater/11.png",
      image2x: "/images/content/heartwater/11.png",
    },
    {
      title: "78",
      image: "/images/content/heartwater/14.png",
      image2x: "/images/content/heartwater/14.png",
    },
    {
      title: "80",
      image: "/images/content/heartwater/16.png",
      image2x: "/images/content/heartwater/16.png",
    },
  ],
  activations: {
    title: "Roadmap",
    head: "",
    items: [
      {
        title: "Main Launch",
        description: ["• 12hr OG presale", "• 24hr whitelist presale"],
        date: "2 days ago",
        image: "/images/content/0p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "10% Sold",
        description: [
          "• Snapshot of holders will receive an airdrop of Heart Water collectible",
        ],
        date: "2 days ago",
        image: "/images/content/10p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "23% Sold",
        description: [
          "• Snapshot of holder for raffle of bonus Melting Hearts NFTs",
          "• 5x raffle of Heart Water VIP gift collection",
        ],
        date: "2 days ago",
        image: "/images/content/23p.png",
        icon: "/images/content/flag.svg",
        color: "#EF466F",
      },
      {
        title: "40% Sold",
        description: [
          "• 2x ultra rare Kat Graham 1/1 Melting Hearts randomly airdropped to holders who minted",
          "• Raffle for custom Melting Hearts of yourself",
        ],
        date: "2 days ago",
        image: "/images/content/40p.png",
        icon: "/images/content/play.svg",
        color: "#141416",
      },
      {
        title: "80% Sold",
        description: ["• 5x raffle for a years supply of Heart Water"],
        date: "2 days ago",
        image: "/images/content/80p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
      {
        title: "100% Sold",
        description: [
          "• Full rarity checker goes live",
          "• 40% of future royalties earned go to the Heart Water Foundation",
          "• Raffle for lunch with Kat Graham, and spa day",
          "• Raffle to have a photoshoot with Kat Graham taken by Marcelo Cantu",
          "• Raffle for a trip to Austin, TX to meet the Founders of Heart Water, lunch, and VIP tour of the facility",
          "• Melting Heart events (SXSW, Consensus, and much more)",
        ],
        date: "2 days ago",
        image: "/images/content/100p.png",
        icon: "/images/content/download.svg",
        color: "#141416",
      },
    ],
  },
  content: {
    name: "Melting Hearts Project",
    sale: "SALE - TBA",
    storyline:
      "Participate in a 4,040 one-of-one PFP drop by culturally renowned digital artist Marcelo Cantu (@marcelocantuphoto), featuring actress and UN ambassador Kat Graham (@katgraham). Marcelo's work is centered around feminine beauty and the independence of women in today's culture. He is known for work with Kim Kardashian, Kylie Jenner, Cardi B, Megan Thee Stallion, and many other prominent female figures. Through the Heart Water Foundation (@heartwater), forty-percent (40%) of all mint proceeds will be donated to help provide clean drinking water for a local community in Marcelo's home town, Rio Grande Valley, Texas.",
  },
  heroImage: {
    image: "/images/content/heartwater/heartwater.gif",
    image2x: "/images/content/heartwater/heartwater.gif",
  },
  users: [
    {
      name: "Marcelo Cantu",
      position: "Artist",
      avatar: "/images/content/heartwater/1.png",
    },
    {
      name: "Kat Graham",
      position: "Featured Actress",
      avatar: "/images/content/heartwater/11.png",
    },
    {
      name: "Heart Water Foundation",
      position: "Builder",
      avatar: "/images/content/heartwater/16.png",
    },
  ],
  discord: {
    title: "Join the Discord. Get Nifty for the drop.",
  },
  socials: [
    {
      title: "twitter",
      url: "https://twitter.com/meltinghearts_",
    },
    {
      title: "discord",
      url: "https://discord.gg/meltinghearts",
    },
  ],
  faq: {
    content: {
      faqhead: "learn more about heart water",
      faqdescription: "FAQ",
      cco: "",
      faqdomain: "",
    },
    items: [
      {
        title: "General",
        icon: "home",
        items: [
          {
            title: "How many Melting Hearts will be minted?",
            description:
              "While there are over 100,000 total possible combinations, only 4,040 Melting Hearts will ever exist in the collection.",
          },
          {
            title: "When does minting begin?",
            description: "To Be Announced",
          },
          {
            title: "How much do they cost to mint?",
            description: "Each Each Melting Heart is 0.10 ETH to mint.",
          },
          {
            title:
              "Is there a limit on how many Melting Hearts can be minted per transaction?",
            description:
              "Up to 20 Melting Hearts can be minted per transaction during the public mint phase.",
          },
          {
            title: "When is the reveal?",
            description:
              "Melting Hearts collection will be revealed after all 4,040 are minted.",
          },
        ],
      },
    ],
  },
};
