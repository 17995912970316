import { Slide, Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import styles from "./Drops.module.sass";
import cn from "classnames";

const cardItems = [
  {
    link: "/trippy-sushi",
    image: "/images/content/sushi/trippyfinal.gif",
    title: "Trippy Sushi",
    description:
      "Mystic rice patty creatures transported from the galaxy Narezushi to Earth. In their journey, the Sushi discovered wild mushrooms in a deep dark forest and evolved into the psychedelic characters you see today.",
  },
  {
    link: "/melting-hearts",
    image: "/images/content/heartwater/heartwater.gif",
    title: "Melting Hearts Project",
    description:
      "Through the Heart Water Foundation (@heartwater), forty-percent (40%) of all mint proceeds will be donated to help provide clean drinking water for a local community in Marcelo's home town, Rio Grande Valley, Texas.",
  },
  {
    link: "/rock-gods",
    image: "/images/content/aijia/rg.gif",
    title: "Rocks Gods",
    description:
      "Rock Gods are the eternal beings watching over the Rock and Roll World. Beings of immense power, these Gods have been the voice in your favorite artists head when they scribble down the lyrics to their most iconic song.",
  },
];

export const Drops = () => {
  return (
    <Fade triggerOnce>
      <div className={cn("container", styles.container)}>
        <Slide direction="up" triggerOnce>
          <h3 className={styles.title}>Upcoming drops</h3>
        </Slide>
        <div className={styles.gridLayout}>
          <Slide direction="up" triggerOnce>
            {cardItems.map((item, id) => {
              return (
                <div className={styles.card} key={id}>
                  <Link to={item.link}>
                    <img
                      src={item.image}
                      className={styles.sushiGif}
                      alt="Avatar"
                    />
                  </Link>
                  <div className={styles.content}>
                    <h4 className={styles.cardTitle}>{item.title}</h4>
                    <p className={styles.description}>{item.description}</p>
                  </div>
                </div>
              );
            })}
          </Slide>
          <Slide direction="up" triggerOnce>
            <div className={styles.card}>
              <img
                src={"/images/content/landingPage/announcing-soon.png"}
                className={styles.sushiGif}
                alt="Coming Soon"
              />
              <div className={styles.content}>
                <h4 className={styles.cardTitle}>Announcing soon</h4>
              </div>
            </div>
          </Slide>
          {/*Array.from({ length: 2 }, (_, i) => (
            <div className={styles.accounceContainer} key={i}>
              <Slide direction="up" triggerOnce>
                <img
                  className={styles.announceImg}
                  src={"/images/content/landingPage/announcing-soon.png"}
                  alt={"Coming soon"}
                />
                <p className={styles.announceContent}>Announcing soon</p>
              </Slide>
            </div>
          ))*/}
        </div>
      </div>
    </Fade>
  );
};
