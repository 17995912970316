import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import {Biconomy} from "@biconomy/mexa";
import { serializeError } from "eth-rpc-errors";


const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const alchemyKey = process.env.REACT_APP_API_URL;
const polygonKey = process.env.REACT_APP_POLYGON_API_URL;
const rinkebyKey = process.env.REACT_APP_RINKEBY_API_URL;
const biconomyKey = process.env.REACT_APP_BICONOMY_API_KEY;

let web3Modal;
let biconomy;

const saleType = {
  closed: "closed",
  pre: "pre",
  main: "main",
};


const generateMetamaskError = () => {
  return (
    <span>
      <p>
        {" "}
        🦊{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://metamask.io/download.html`}
        >
          You must install Metamask, a virtual Ethereum wallet, in your browser.
        </a>
      </p>
    </span>
  );
};

export class Interact {
  constructor(copy) {
    this.copy = copy;
    this.setProvider();
    this.setContract();
    this.setWhiteList();
    this.createMerkleTrees();
  }

  copy() {
    return this.copy;
  }

  setProvider() {
    // default
    let rpc = {1: alchemyKey};
        // check network
    if (this.copy.contract) {
      this.network = this.copy.contract.network;
      if (this.network == "Ethereum") {
        rpc = {1: alchemyKey};
        this.web3 = createAlchemyWeb3(alchemyKey);
      } else if (this.network == "Polygon") {
        rpc = {137: polygonKey};
        this.web3 = createAlchemyWeb3(polygonKey);
      } else if (this.network == "Rinkeby") {
        rpc = {4: rinkebyKey};
        this.web3 = createAlchemyWeb3(rinkebyKey);
      }
    } else {
      this.web3 = createAlchemyWeb3(rinkebyKey);
    }

    // get chainId rpc
    let rpcKeys = Object.keys(rpc)

    // setup providers
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            rpc: 137,
          }
        }
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Niftybase",
          rpc: rpc[rpcKeys[0]],
          chainId: rpcKeys[0],
          darkMode: false
        }
      }
    };

    web3Modal = new Web3Modal({
      providerOptions,
    });

  }

  setContract() {
    if (this.copy.contract) {
      this.contract = new this.web3.eth.Contract(
        require(`../contracts/${this.copy.contract.name}.json`).abi,
        this.copy.contract.address
      );
    }
  }

  setWhiteList() {
    this.whitelist = require(`../whitelist/${this.copy.contract.whitelist}.json`);
  }

  createMerkleTrees() {
    const ogList = this.whitelist.og.map((address) => keccak256(address));
    const preList = this.whitelist.pre.map((address) => keccak256(address));

    this.ogMerkleTree = new MerkleTree(ogList, keccak256, { sortPairs: true });
    this.preMerkleTree = new MerkleTree(preList, keccak256, {
      sortPairs: true,
    });

    console.log("og merkle root: " + this.ogMerkleTree.getHexRoot());
    console.log("pre merkle root: " + this.preMerkleTree.getHexRoot());
  }

  isOg = (address) => {
    const proof = this.ogMerkleTree.getHexProof(keccak256(address));
    return proof.length > 0 ? true : false;
  };

  isPre = (address) => {
    const proof = this.preMerkleTree.getHexProof(keccak256(address));
    return proof.length > 0 ? true : false;
  };

  generatePreProof(address) {
    const proof = this.preMerkleTree.getHexProof(keccak256(address));
    console.log("->PRE CHECK");
    console.log("pre address: " + address);
    console.log("pre proof: " + proof);
    return proof.length > 0 ? true : false;
  }

  generateOGProof(address) {
    const proof = this.ogMerkleTree.getHexProof(keccak256(address));
    console.log("->OG CHECK");
    console.log("og address: " + address);
    console.log("og proof: " + proof);
    return proof.length > 0 ? true : false;
  }

  getEtherscanURL() {
    if (this.network == "Ethereum") {
      return "https://etherscan.io/";
    } else if (this.network == "Polygon") {
      return "https://polygonscan.com/";
    } else if (this.network == "Rinkeby") {
      return "https://rinkeby.etherscan.io/";
    }
  }

  async getCurrentSale() {
    try {
      const preSale = await this.contract.methods
        .preSaleActive()
        .call()
        .then(function (result, error) {
          if (!error) {
            return result;
          }
        });
      const mainSale = await this.contract.methods
        .mainSaleActive()
        .call()
        .then(function (result, error) {
          if (!error) {
            return result;
          }
        });

      if (mainSale) {
        return saleType.main;
      } else if (preSale) {
        return saleType.pre;
      } else {
        return saleType.closed;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async sendTransaction(transactionParameters) {
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      return {
        success: true,
        txhash: txHash,
        status:
          "✅ Check out your transaction on Etherscan: " +
          this.getEtherscanURL() +
          "tx/" +
          txHash,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  }

  async pendingTransaction(txHash) {
    const millis = 20000; // 20 secs mx

    const timeout = new Promise((resolve, reject) =>
      setTimeout(() => {
        reject(`Timed out after ${millis} ms.`);
        this.web3.eth.clearSubscriptions();
      }, millis)
    );

    const pending = new Promise(async (resolve, reject) => {
      // Listen for new blocks and check if transaction has been mined
      // BUG - txHash would be 0 causing program to crap out
      try {
        this.web3.eth
          .subscribe("newBlockHeaders")
          .on("data", async (block) => {
            console.log("txhash: " + txHash);
            var receipt = this.web3.eth
              .getTransactionReceipt(txHash)
              .then((receipt) => {
                if (receipt) {
                  console.log("receipt: " + receipt);
                  this.web3.eth.clearSubscriptions();
                  resolve(receipt.transactionHash);
                }
              })
              .catch((error) => {
                this.web3.eth.clearSubscriptions();
                console.log(error);
                reject(error);
              });
          })
          .on("error", (error) => {
            this.web3.eth.clearSubscriptions();
            console.log(error);
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

    return Promise.race([pending, timeout]);
  }

  /* ABI METHODS */
  getTotalMinted() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.totalSupply().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getMaxMints() {
    try {
      const sale = await this.getCurrentSale();
      if (sale == saleType.main) {
        return this.getMaxMainMints();
      } else if (sale == saleType.pre) {
        return this.getMaxPreMints();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getMaxMainMints() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.MAX_MINTS().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  getMaxPreMints() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.MAX_PRE_MINTS().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  getMaxOGMints() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.MAX_OG_MINTS().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  getMaxTokens() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.MAX_TOKENS().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  getTokenPrice() {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.TOKEN_PRICE().call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  getBalanceOf(address) {
    try {
      return new Promise(async (resolve) => {
        this.contract.methods.balanceOf(address).call(function (error, result) {
          if (!error) {
            resolve(result);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Returns the newest token that selected address owns
  async getNewestToken(address) {
    try {
      const numTokens = await this.getBalanceOf();
      const tokenIndex = numTokens - 1;

      return new Promise(async (resolve) => {
        this.contract.methods
          .tokenOfOwnerByIndex(address, tokenIndex)
          .call(function (error, result) {
            if (!error) {
              resolve(result);
            }
          });
      });
    } catch (error) {
      console.error(error);
    }
  }

  async mintNifties(count, address) {
    // wei conversions
    var BN = this.web3.utils.BN;
    const baseWeiCost = await this.getTokenPrice();
    const totalWeiCost = new BN(baseWeiCost).mul(new BN(count));
    const totalWeiCostHex = this.web3.utils.toHex(totalWeiCost);

    // tx params
    const transactionParameters = {
      to: this.copy.contract.address,
      from: address,
      value: totalWeiCostHex,
      signatureType: Biconomy.PERSONAL_SIGN
    };

    // handle sale type
    const sale = await this.getCurrentSale();
    console.log("saleType:" + sale);

    let tx;

    if (sale == saleType.pre) {
      const proof = this.preMerkleTree.getHexProof(keccak256(address));
      try {
        tx = await this.contract.methods.mintPreSaleTokens(count, proof).send(transactionParameters);
      } catch (e) {
        // TODO: figure out better rpc error handling
        let returnMessage = "Transaction Error"
        console.log(e.message)
        const serializedError = serializeError(e);
        if (serializedError.code == -32603) {
          returnMessage = "Minting can take up to 15 minutes, please wait for confirmation on OpenSea!"
        }
        return {
          success: false,
          status: returnMessage
        }
      }
    } else {
      tx = await this.contract.methods.mintTokens(count).send(transactionParameters);
    }
    return {
      success: true,
      txHash: tx.transactionHash
    }
  }

  /* WALLET METHODS */
  connectWallet = async () => {
    try {
      // grab provider from wallet
      const provider = await web3Modal.connect();
      console.log(provider.chainId)
      // set up biconomy
      biconomy = new Biconomy(provider,
        {apiKey: biconomyKey, debug: true
      });
      this.web3 = new Web3(biconomy);
      // refresh contract provider
      this.setContract() 
      // grab account
      const accounts = await this.web3.eth.getAccounts();
      return {
        success: true,
        address: accounts[0]
      }
    } catch (err) {
      console.log(err)
      return {
        success: false,
        address: ""
      }
    }
    //this.setProvider();
    /*
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "success",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: generateMetamaskError(),
      };
    }
    */
  };

  getCurrentWalletConnected = async () => {
    console.log("getting current wallet connected");
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        console.log("current wallet connected: " + addressArray);
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "success",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: generateMetamaskError(),
      };
    }
  };

  getCurrentChainID = async () => {
    if (window.ethereum) {
      try {
        const chainId = window.ethereum.request({ method: "eth_chainId" });
        return chainId;
      } catch (err) {
        console.log(err);
      }
    }
  };

  /* HELPER FUNCTIONS */
  toEther = (wei) => {
    const ether = this.web3.utils.fromWei(wei);
    return parseFloat(ether);
  };

  toChecksumAddress = (address) => {
    if (address) {
      return this.web3.utils.toChecksumAddress(address);
    }
    return "";
  };

  signMessage = async (message, address) => {
    if (address != null) {
      const signature = await this.web3.eth.personal.sign(message, address);
      return signature;
    }
  };
}
