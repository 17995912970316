import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";

const content = {
  faqhead: "learn more about sushi",
  faqdescription: "FAQ",
  faqdomain:
    "Public Domain CC0 To the extent possible under law, Sushi Creators have waived all copyright and related or neighboring rights to Trippy Sushi's. This work is published from:United States.",
};

const FAQHero = ({ faq }) => {
  const options = [];
  faq.items.map((x) => options.push(x.title));

  const [direction, setDirection] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          {/*<div className={styles.stage}>{content.faqhead}</div>*/}
          <h1 className={cn("h2", styles.title)}>
            {faq.content.faqdescription}
          </h1>
          {/*
          <div className={styles.info}>
            Join Stacks community now to get free updates and also alot of
            freebies are waiting for you or{" "}
            <a href="/#" rel="noopener noreferrer">
              Contact Support
            </a>
          </div>
          */}
          {/*
          <Dropdown
            className={cn("mobile-show", styles.dropdown)}
            value={direction}
            setValue={setDirection}
            options={options}
          />
          */}
        </div>
        <div>
          {faq.items
            .find((x) => x.title === direction)
            .items.map((x, index) => (
              <Item className={styles.item} item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQHero;
