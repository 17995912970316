import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import styles from "./EmailBox.module.sass";
import Form from "../../../../components/Form";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Modal from "../../../../components/Modal";

const EmailBox = ({ className, status, message, onValidated, callback }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
    setEmail("");
  };

  return (
    <>
      <Form
        className={styles.form}
        value={email}
        setValue={setEmail}
        onSubmit={(e) => handleSubmit(e)}
        placeholder="Enter your email"
        type="email"
        name="email"
      />
    </>
  );
};

const MailchimpForm = (props) => {
  const url = `https://${process.env.REACT_APP_MAILCHIMP_URL}/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <>
            <EmailBox
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
              callback={props.callback}
            />
            {status === "sending" && (
              <div style={{ color: "blue" }}>sending...</div>
            )}
            {status === "error" && (
              <div style={{ color: "red" }}>Error, could not subscribe!</div>
            )}
            {status === "success" && (
              <div style={{ color: "green" }}>Subscribed!</div>
            )}
          </>
        )}
      />
    </div>
  );
};

export { MailchimpForm, EmailBox };
