import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../Form";
import Theme from "../Theme";
import { MailchimpForm } from "../../screens/Home/Hero/EmailBox";
import Icon from "../Icon";

const items = [
  {
    title: "Crypter.",
    menu: [
      {
        title: "Discover",
        url: "/search01",
      },
      {
        title: "Connect wallet",
        url: "/connect-wallet",
      },
    ],
  },
  {
    title: "Info",
    menu: [
      {
        title: "FAQ",
        url: "/faq",
      },
      {
        title: "Create item",
        url: "/upload-variants",
      },
    ],
  },
];

const socials = [
  {
    title: "twitter",
    url: "https://twitter.com/niftybase",
    viewbox: "0 0 16 16",
  },
  {
    title: "discord",
    url: "https://discord.gg/yZKd3nAfuE",
    viewbox: "0 0 256 293",
  },
  {
    title: "medium",
    url: "https://medium.com/niftybase",
    viewbox: "0 0 24 24",
  },
];

const Footers = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <img src="/nifty-base-logo-180.png" />
              <h2>Niftybase</h2>
            </Link>
            <div className={styles.info}>
              The intergalactic headquarters for all drops nifty
            </div>
            <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size="24" viewbox={x.viewbox} />
                </a>
              ))}
            </div>
            {/*
            <div className={styles.version}>
              <div className={styles.details}>Dark theme</div>
              <Theme className="theme-big" />
            </div>
            */}
          </div>
          <div className={styles.col}></div>
          <div className={styles.col}>
            <div className={styles.category}>Join Newsletter</div>
            <div className={styles.text}>Subscribe for Upcoming Drops</div>
            <MailchimpForm />
            <div className={styles.foot}>
              <div className={styles.copyright}>© 2021 Copyright Niftybase</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
