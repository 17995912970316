import { Fade } from "react-awesome-reveal";
import styles from "./Steps.module.sass";
import cn from "classnames";

const items = [
  {
    icon: "/images/content/upload-icon.svg",
    title: "Upload",
    description:
      "Upload a folder of NFT attributes, with at least five variations per attribute. All attributes must align on the icon’s X and Y axis’.",
  },
  {
    icon: "/images/content/setup-icon.svg",
    title: "Setup",
    description:
      "Set up the collection details and drop information, including theme, story, quantity, rarity, price, royalties, and date.",
  },
  {
    icon: "/images/content/sell-icon.svg",
    title: "Drop",
    description:
      "Drop the final NFT collection through a custom sales page for your project. The collection will be ready for mint by your community!.",
  },
];

export const Steps = () => {
  return (
    <div className={cn("container", styles.container)}>
      <Fade delay={200} triggerOnce>
        {items.map((item, id) => {
          return (
            <div className={styles.item} key={id}>
              <div className={styles.image}>
                <img src={item.icon} alt={item.title} />
              </div>
              <h4 className={styles.title}>{item.title}</h4>
              <p className={styles.description}>{item.description}</p>
            </div>
          );
        })}
      </Fade>
    </div>
  );
};
