import { Slide, Fade } from "react-awesome-reveal";
import styles from "./Hero.module.sass";
import cn from "classnames";

export const Hero = () => {
  return (
    <Fade triggerOnce>
      <div className={styles.container}>
        <div className={styles.flex}>
          <Slide direction="up" triggerOnce>
            <div className={styles.content}>
              <img
                src={"/images/content/landingPage/niftyBase.png"}
                alt="Nifty Logo"
                className={styles.logo}
              />
              <h1 className={cn("h1", styles.title)}>
                Create your own <br />
                NFT collection
              </h1>
              <p className={styles.description}>
                We enable creators, brands, and builders to easily launch NFT
                collections. Focus on what really matters — the artwork,
                utility, and community.
              </p>
              <div className={styles.buttonContainer}>
                <a
                  href="https://discord.com/invite/yZKd3nAfuE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={cn("button", styles.button)}>
                    Join our Discord
                  </button>
                </a>
              </div>
            </div>
          </Slide>
        </div>
        <Slide direction="up" delay={1500} triggerOnce>
          <div className={styles.avatarsContainer}>
            <img
              src={"/images/content/landingPage/niftyHeroImage.png"}
              alt="Avatars"
              className={styles.avatars}
            />
          </div>
        </Slide>
      </div>
    </Fade>
  );
};
